import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import "./assets/scss/style.scss";
import "./assets/css/general.css";
import "./assets/css/Override.css";
import * as serviceWorker from "./serviceWorker";
import store from "./store";
import { Toaster } from "react-hot-toast";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <Provider store={store}>
    <App />
    <Toaster position="bottom-center" />
  </Provider>
);

serviceWorker.unregister();
